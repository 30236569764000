export const GUIDE_NAV = {
    guides_home: '/',
    guides_tab_detox: '/detox',
    guides_tab_outpatient: '/outpatient',
    guides_tab_inpatient: '/inpatient',
    guides_tab_php: '/partial-hospitalization-program',
    guides_tab_soberliving: '/sober-living',
    guides_tab_telehelp: '/telemedicine-providers',


}