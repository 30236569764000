import React from 'react'
import { Collapse } from 'reactstrap';
import { StoresLink } from './components/layout/SingleProduct'


const MenuItem = ({ title, onClick, link }) => {
    if (onClick) {
        return <p
            className="hover hover-1"
            onClick={() => {
                if (onClick) {
                    onClick()
                }
            }}
        >
            {title}
        </p>
    }
    return <a
        className="hover hover-1"
        href={link}
    >
        {title}
    </a>
}

const Drawer = (props) => {

    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const { origin } = window.location;

    return (
        <>
            <div id="mySidenav" className="sidenav">

                <div className="menu d-flex flex-column justify-content-center" >

                    <MenuItem
                        title="Home"
                        link={"https://loosidapp.com/"}
                    />

                    <section className="d-flex align-items-center" onClick={toggle}>
                        <MenuItem
                            title="Loosid Features"
                        />


                        <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            className="bi bi-caret-down-fill down-carret ml-1"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                    </section>
                    <Collapse isOpen={isOpen} >

                        <section className="sub-menu" >

                            <MenuItem
                                title="Sober Dating"
                                link={"https://loosidapp.com/sober-dating/"}
                            />

                            <MenuItem
                                title="Sobriety Help"
                                link={"https://loosidapp.com/sobriety-help/"}
                            />

                            <MenuItem
                                title="Boozeless Guides"
                                link={"https://loosidapp.com/boozeless-guides/"}
                            />

                            <MenuItem
                                title="Chat Groups"
                                link={"https://loosidapp.com/chat-groups/"}
                            />

                            <MenuItem
                                title="Rehab Guides"
                                link={"https://loosidapp.com/rehabs/"}
                            />
                        </section>

                    </Collapse>

                    <MenuItem
                        title="Centers"
                        link={`${origin}/rehabs/inpatient/alabama/`}
                    />

                    <MenuItem
                        title="About"
                        link={"https://loosidapp.com/about-us/"}
                    />

                    <MenuItem
                        title="Blog"
                        link={"https://loosidapp.com/blog/"}
                    />

                    <MenuItem
                        title="Media"
                        link={"https://loosidapp.com/media-assets/"}
                    />


                    <MenuItem
                        title="Contact"
                        link={"https://loosidapp.com/contact-us/"}
                    />

                    <div className="apps">

                        <StoresLink />
                    </div>
                </div>


            </div>
        </>
    )
}



export default Drawer