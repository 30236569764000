import { initializeMixpanel, trackMixpanelEvent } from './mixpanel/mixpanel'

import { initializeGoogleAnalytics } from './google-analytics/analytics'


export const initializeTracking = () => {

    initializeGoogleAnalytics();
    initializeMixpanel()

}

export const trackEvent = (params = { eventName: "", data: {} }) => {

    trackMixpanelEvent(params)

}