import React from 'react'
import apple from '../../assets/img/apple-logo.svg';
import google_play from '../../assets/img/google-play-logo.svg';
import {
    Container,
    Col,
    Row,

} from 'reactstrap';

import { mix_panel_event_name } from '../../helpers/tracking/mixpanel/mixpanel-constants';
import { trackEvent } from '../../helpers/tracking/tracking';

const Button = ({ className, image, text, link, event = undefined }) => {
    return <a
        className={`d-flex p-0 justify-content-around align-items-center button ${className}`}
        href={link}
        target="_BLANK"
        onClick={() => {
            if (event) {
                trackEvent({ eventName: event })
            }
        }}
    >

        <img src={image} alt="logo" />

        <p className="p-0 m-0" style={{ fontSize: "1rem" }}>{text}</p>

    </a>;
}


export const StoresLink = () => {
    return (
        <>
            <Button
                className={"ios-button"}
                image={apple}
                text={'App Store'}
                link={"https://apps.apple.com/us/app/loosid-sober-healthy-living/id1413514350?ls=1"}
                event={mix_panel_event_name.app_store_click}
            />

            <Button
                className={"play-store-button"}
                image={google_play}
                text={'Play Store'}
                link={"https://play.google.com/store/apps/details?id=com.loosidapp&hl=en"}
                event={mix_panel_event_name.play_store_click}
            />
        </>
    )
}

const SingleProduct = (props) => {
    return (
        <div className="single-product mt-4 mb-4 d-flex justify-content-center align-items-center p-2 p-md-0">

            <Container >
                <Row className="align-items-stretch"  >
                    <Col sm={12} md={7} className="d-flex justify-content-center" >
                        <span className='single-product-text'> Join a Hotline and help others with their sobriety</span>
                    </Col>

                    <Col sm={12} md={5}  >
                        <p className="app-links mb-3 mt-4 mt-md-0 ml-lg-0 ml-xl-5" >
                            {'get the app'}
                        </p>
                        <div className="apps d-flex justify-content-center justify-content-sm-center justify-content-md-end">

                            <StoresLink />
                        </div>

                    </Col>
                </Row>
            </Container>


        </div>
    )
}
export default SingleProduct