import React, { useState } from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import { GUIDE_NAV } from "../Guide/constants/_nav";

import logo from "../../assets/img/logo-head.svg";
import logo_fixed from "../../assets/img/logo-head-fixed.svg";

import drawer_icon from "../../assets/img/drawer_icon.svg";
import drawer_icon_fixed from "../../assets/img/drawer_icon_fixed.svg";
import { BannerContext } from "../../contexts/BannerContext";



let isNavOpen = false;

const openNav = () => {
  isNavOpen = true;
  const width = "26rem";
  document.getElementById("mySidenav").style = `
        width: ${width};
        // transition: opacity 0.35s ease-in-out;
    `;

  document.getElementById("main").style.marginRight = width;
  document.body.style.backgroundColor = "#09A68F";

  document.querySelector(".fixed-content").style = `
        dislay:block;
        z-index: 100;
    `;

  document.querySelector(".menu").style = `
        transition: all 0.5s ease-in-out;
        transform:translateX(0px);
    `;

  document.querySelector(".init").style =
    "transition: all 0.5s ease-in-out;padding-top:2.5%";
  document.querySelector(".main-app").style = `
        max-height: 90vh;
        overflow: hidden;
        transition: all 0.35s ease-in-out;
        transform: scaleX(0.9) scaleY(0.9)  translateX(-30.5rem);
        border-top-right-radius: 15px; 
        border-bottom-right-radius: 15px;
    `;
};

export const closeNav = () => {
  isNavOpen = false;

  document.getElementById("main").style.marginRight = "0";
  document.body.style.backgroundColor = "#F5F5F5";

  document.getElementById("mySidenav").style = `
        width: 0;
    `;

  document.querySelector(".fixed-content").style = `
        dislay:none;
        z-index: -100;
    `;

  document.querySelector(".menu").style = `
            transition: all 0.5s ease-in-out; 
            transform:translateX(100px); 
            `;

  document.querySelector(".init").style = `
        transition: all 0.5s ease-in-out;
        padding-top:0%
    `;
  document.querySelector(".main-app").style = `
        overflow: visible;
        height: 100vh;
        transition: all 0.35s ease-in-out;
        transform: scaleX(1) scaleY(1) translateX(0px);
        border-top-right-radius: 0px; border-bottom-right-radius: 0px;
    `;

  setTimeout(() => {
    document.querySelector(".main-app").style = null;
  }, 300);
};

export const FixedContent = () => {
  return <div
    className="fixed-content"
    onClick={() => {
      if (isNavOpen) {
        closeNav()
      }
    }}
  />;
};

export default function GuidesNav() {
  const [isScrollable, setScroll] = useState(true);
  const [fixed, setFixed] = useState(false);


  const { show, toggle } = React.useContext(BannerContext);

  const scrolling = (event) => {
    const scrollTop = event.target.documentElement
      ? event.target.documentElement.scrollTop
      : 0;

    if (scrollTop > 50) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", scrolling, true);

    return function cleanup() {
      window.removeEventListener("scroll", scrolling);
    };
  }, []);

  return (

    <div
      className={`fixed-top ${show ? "" : "fixed-top-override"}`}
      style={{
        background: fixed ? "white" : "transparent",
      }}
    >

      <Container
        className={`d-flex justify-content-between
         ${fixed ? "pt-2 pb-2 mt-0" : "pt-0 pb-0 mt-2"}`}
      >
        <div className={`d-flex align-items-center`}>

          <a
            className="m-0 p-0 d-flex"
            href={'https://loosidapp.com/'}
            style={{
              textDecoration: "none",
            }}
          >
            <div className={`d-flex align-items-center`}>
              <img
                src={fixed ? logo_fixed : logo}
                className="logo"
                alt="logo"
                width={44}
                height={44}
              />
              <h2 className={`d-none d-md-block logoTitle p-0 m-0 ${fixed ? "text-green" : ""}`}>
                {"LOOSID"}
              </h2>

            </div>
          </a>

          <Link
            className="m-0 p-0 d-flex "
            to={GUIDE_NAV.guides_home}
            style={{
              textDecoration: "none",
            }}
          >
            &nbsp;
            <h2
              className={`d-none d-md-block logo-subTitle p-0 m-0 ${fixed ? "text-green" : ""}`}
            >{`RehabGuide`}</h2>
          </Link>
        </div>

        <div className={`d-flex align-items-center `} style={{
          width: ' 2rem',
          height: '2rem'
        }}>

          <img
            src={fixed ? drawer_icon_fixed : drawer_icon}
            alt="drawer_icon"
            className="drawer-button"
            onClick={() => {
              if (isScrollable) {
                openNav();
              } else {
                closeNav();
              }
              setScroll(!isScrollable);
            }}
          />
        </div>
      </Container>
    </div>
  );
}


