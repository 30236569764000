import React from 'react'


function loader(size = "sm", _color = "#69D8ED") {
    return (
        <div
            className={"spinner-border spinner-border-" + size}
            style={{
                color: _color
            }}
        />
    )
}

export default loader
