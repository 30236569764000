import React, { useEffect } from 'react'
import ReactGA from 'react-ga';


import { Route, Switch, useHistory } from 'react-router-dom';

import { GuidesRouter } from './components/Guide/constants/GuideRoutes';

import loader from './components/common/loader';

const AppRouter = () => {
    const history = useHistory()

    useEffect(() => {
        return history.listen((location) => {
            ReactGA.pageview(`rehabs${location.pathname}`);
        })
    }, [history])

    return (
        <section className='main'>

            <React.Suspense fallback={loader()}>
                <Switch>
                    {
                        GuidesRouter.map((route, index) => {
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={props => (
                                        <route.component {...props} />
                                    )}
                                />
                            );
                        })
                    }

                </Switch>
            </React.Suspense>

        </section>
    )
}

export default AppRouter