import { slugToString } from '../helpers/string-functions';

export const BASE_URL = 'https://api.loosid.cc/v3/'
const FEEDS_BASE_URL = 'https://ag01.loosid.cc/v3';


export const GUIDES_URL = (params) => {
    let URL = `${BASE_URL}listings/search/count?guideName=${params.guideName}&rehabCategory=${params.rehabCategory}&limit=${params.limit}`;

    if (params.location.length) {
        URL = `${URL}&lat=${params.location[0]}&lng=${params.location[1]}&miles=${params.radius}`
    } else {
        URL = `${URL}&state=${params.state}`

        if (params.keywords) {
            URL = `${URL}&keywords=${slugToString(params.keywords)}`
        }
    }

    URL = `${URL}&offset=${params.offset * params.limit}&showAds=true`



    return URL
}

export const LISTING_PROFILE_URL = `${BASE_URL}listings/listing/`

export const FEATURED_LISTING_URL = (params) => `${BASE_URL}listings/featured?carouselID=${params.carousel_id}&guideName=rehab`

export const ROTATING_PHONE_NUMBER_URL = `${FEEDS_BASE_URL}/get_banner?type=website`;