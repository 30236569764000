import mixpanel from 'mixpanel-browser';
import Axios from 'axios'

import { MIX_PANEL_DATA } from './mixpanel-constants'




export const initializeMixpanel = () => {

    mixpanel.init(MIX_PANEL_DATA.PROJECT_TOKEN);

}

export const trackMixpanelEvent = async (params = { eventName: "", data: {} }) => {
    // console.log(params)
    // mixpanel.track(params.eventName, Object.keys(params.data || {}).length ? params.data : null);

    if (true) {

        const keys = Object.keys(params.data || {}) || [];

        const properties_arr = []

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            const data = params.data[key];

            const str = `"${key}":"${data}" `

            properties_arr.push(str);

        }

        var data = `data={
        "event": "${params.eventName}",
        "properties": {
            "token": "${MIX_PANEL_DATA.PROJECT_TOKEN}"${properties_arr.length ? "," : ""}
            ${properties_arr.join(",")}
        }
    }`;

        var config = {
            method: 'post',
            url: 'https://api.mixpanel.com/track',
            headers: {
                'Content-Type': 'text/plain'
            },
            data: data
        };

        Axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
