const { host } = window.location;

export const MIX_PANEL_DATA = {
    PROJECT_TOKEN: host === "loosidapp.com" ? '962f9636a8a881591638111c57e2a7d8' : 'ed68e89a229f05e69690d10bb46108eb',
    API_KEY: '8fcc49df719cf7a7dcf393092c317377'
}

export const mix_panel_event_name = {
    search_event: 'Search Event',
    event_click: 'Event Click',
    distance_selector_event: 'Distance Selector Event',
    featured_ad_pos: 'Featured Ad Position',
    top_listing_pos: 'Top Listing Position',
    standard_listing_pos: 'Standard Listing Position',

    app_store_click: 'App Store Click',
    play_store_click: 'Play Store Click',

    distance_selector_click: 'Distance Selector Click - Select',
    tab_click: 'tab click', //outpatient / detox / php / living / tele help
    featured_ad_click: 'Featured ad click',
    top_listing_click: 'Top Listing Click',
    standard_listing_click: 'Standard Listing Click',


    listing_url_open: 'Listing URL Open',


    featured_ad_impression: 'Featured ad Impressions',
    top_listing_impression: 'Top Listing Impressions',

}