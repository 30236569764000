import React from "react";
import { GUIDE_NAV } from "./_nav";

import stateList from "../constants/states.json";
import { stringToSlug } from "../../../helpers/string-functions";

const ListingDetail = React.lazy(() => import("../ListingDetail"));
const Guides = React.lazy(() => import("../index"));
const LandingPage = React.lazy(() => import("../LandingPage"));

export const guideRoutes = [
  {
    path: GUIDE_NAV.guides_tab_inpatient,
    pageTitle: () => <div>Inpatient Treatment Centers</div>,
    categoryName: "Inpatient",
    activeTab: "3",
    navTitle: "Inpatient",
    tooltip:
      "A residential treatment center, sometimes called a rehab, is a live-in health care facility providing therapy for substance abuse, mental illness, or other behavioral problems. Visit a Inpatient Treatment Center near you.",
    category: "inpatient", // required for search in the API
    carouselID: "22",
  },
  {
    path: GUIDE_NAV.guides_tab_outpatient,
    pageTitle: () => <div>Outpatient Treatment Centers</div>,
    categoryName: "Outpatient",
    activeTab: "2",
    navTitle: "Outpatient",
    tooltip:
      "An intensive outpatient program (IOP) is a form of substance abuse rehabilitation in which people visit a treatment center several days a week. Visit a Outpatient Treatment Center near you.",
    category: "iop",
    carouselID: "24",
  },
  {
    path: GUIDE_NAV.guides_tab_detox,
    exact: true,
    pageTitle: () => <div>Detox</div>,
    categoryName: "Detox",
    activeTab: "1",
    navTitle: "Detox",
    tooltip:
      "Detoxification, or detox treatment , is the process of letting the body remove the drugs in it. The purpose of detox is to safely manage withdrawal symptoms when someone stops taking drugs or alcohol. Visit a Detox Treatment Center near you.",
    category: "detox",
    carouselID: "25",
  },
  {
    path: "/",
    exact: true,
    pageTitle: () => <div>Detox</div>,
    categoryName: "Detox",
    tooltip:
      "Detoxification, or detox treatment , is the process of letting the body remove the drugs in it. The purpose of detox is to safely manage withdrawal symptoms when someone stops taking drugs or alcohol. Visit a Detox Treatment Center near you.",
    category: "detox",
    carouselID: "25",
  },
  {
    path: GUIDE_NAV.guides_tab_php,
    pageTitle: () => <div>Partial Hospitalization Program</div>,
    categoryName: "Partial Hospitalization Program",
    activeTab: "4",
    navTitle: "PHP",
    tooltip:
      "Partial hospitalisation program is a non-residential, therapy-based type of treatment for addiction help. Discover a Partial hospitalisation program near you.",
    category: "php",
    carouselID: "26",
  },
  {
    path: GUIDE_NAV.guides_tab_soberliving,
    pageTitle: () => <div>Sober Living</div>,
    categoryName: "Sober Living",
    activeTab: "5",
    navTitle: "Living",
    tooltip:
      "Sober living houses (SLHs), also called sober homes and sober living environments, are facilities that provide sober help by providing safe housing and supportive, structured living conditions for people exiting drug rehabilitation programs.",
    category: "living",
    carouselID: "27",
  },
  {
    path: GUIDE_NAV.guides_tab_telehelp,
    pageTitle: () => <div>Telemedicine Providers</div>,
    categoryName: "Telemedicine Providers",
    activeTab: "6",
    navTitle: "Tele Help",
    tooltip:
      "Telemedicine refers to the practice of caring for patients remotely when the provider and patient are not physically present with each other. Modern technology has enabled doctors to consult patients by using HIPAA compliant video-conferencing tools.",
    category: "telemedicine",
    carouselID: "23",
  },
];

const categoryWithStates = stateList.map((item) => {
  return `/:category/${stringToSlug(item.name)}`;
});

export const GuidesRouter = [
  {
    path: GUIDE_NAV.guides_home,
    exact: true,
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: [
      GUIDE_NAV.guides_tab_detox,
      GUIDE_NAV.guides_tab_outpatient,
      GUIDE_NAV.guides_tab_inpatient,
      GUIDE_NAV.guides_tab_php,
      GUIDE_NAV.guides_tab_soberliving,
      GUIDE_NAV.guides_tab_telehelp,
      // ...PREVIEWS,
      `/:state/:city/:category/preview/:listing`, // show preview of listing
      `/:category/:state/:city`, // show listing with state and city
      `/:category/:state/:city/:zipcode`, // show listing with state, city and zipcode
      ...categoryWithStates, // show all listing in state with category
    ],
    exact: true,
    name: "Guides",
    component: Guides,
  },
  {
    path: `/:category/:listing`,
    exact: true,
    name: "ListingDetail",
    component: ListingDetail,
  },
];