import React from 'react'
import {
    Container,
    Button,
    PopoverHeader,
    PopoverBody,
    UncontrolledPopover
} from "reactstrap";
import useAxios from 'axios-hooks'
import { Link } from "react-router-dom"

import { BannerContext } from '../../contexts/BannerContext';
import { dialNumber } from '../../helpers/Util';
import { stringToSlug } from '../../helpers/string-functions';

import {
    ROTATING_PHONE_NUMBER_URL,
} from '../../constants/ApiManager';

import icon_phone from '../../assets/img/phone-green.svg'
import icon_Info from '../../assets/img/icon_Info.svg'
import right_arrow from '../../assets/img/arrow-right.svg'
import cross from '../../assets/img/cross.svg'


const Rehabs = [
    // {
    //     listingID: "95552",
    //     listingName: "Legacy Healing Center"
    // },
    // {
    //     listingID: "145128",
    //     listingName: "Mountainside Treatment Center "
    // },
    {
        listingID: "147099",
        listingName: "Chapel Hill Medical Detox"
    }
]

const PopoverRehab = ({ item }) => {
    const { listingName, listingID } = item;
    const { origin } = window.location

    return <a
        className="d-flex justify-content-between"
        style={{ textDecoration: 'none' }}


        href={`${origin}/rehabs/inpatient/${stringToSlug(listingName)}-${listingID}`}
    >
        <p className="popover-rehab" >{item.listingName}</p>
        <img
            src={right_arrow}
            width={24}
            height={24}
        />
    </a>
}


export default function RotatingPhoneView() {

    const [{ data, loading, error }, next] = useAxios(ROTATING_PHONE_NUMBER_URL);

    const { show, toggle } = React.useContext(BannerContext);

    const [bannerVisible, setBannerVisibility] = React.useState(true);
    if (bannerVisible) {
        return (
            <div className="rotating-phone">
                <Container className={`pt-2 pb-2  ${show ? "d-flex" : "d-none "}  flex-row justify-content-center  h-100 `}>
                    <div className="d-flex justify-content-between" >
                        <button
                            className="close-btn btn btn-primary d-flex align-items-center justify-content-center"
                            onClick={() => {
                                setBannerVisibility(false)
                            }}
                        >
                            <img
                                src={cross}
                                width={12}
                                height={12}
                            />
                        </button>

                        <section className="d-flex ">
                            <p className="center-text mb-0 mr-2 ml-2" >
                                {"Struggling? Treatment professionals are waiting for your call"}
                                <img
                                    style={{ marginLeft: "8px" }}
                                    src={icon_Info}
                                    width={22}
                                    height={22}
                                    id="PopoverLegacy"
                                />
                            </p>


                            <UncontrolledPopover trigger="legacy" placement="bottom" target="PopoverLegacy">
                                <PopoverHeader>{`Where do calls go?`}</PopoverHeader>
                                <PopoverBody>
                                    <p>
                                        {`Calls to numbers on a specific treatment center listing will be routed to that treatment center. Additional calls will also be forwarded and returned by one of our treatment partners below.`}
                                    </p>

                                    {
                                        Rehabs.map((item, i) => <PopoverRehab item={item} key={i} />)
                                    }


                                </PopoverBody>
                            </UncontrolledPopover>


                        </section>

                    </div>

                    <section  >
                        {
                            (() => {

                                if (data?.ad && data?.ad?.phoneNumber) {

                                    return <Button
                                        className="btn-white btn-number "
                                        onClick={() => {
                                            dialNumber(data.ad.phoneNumber)
                                        }}
                                    >
                                        {data.ad.phoneNumber}

                                    </Button>
                                }
                                return <p />
                            })()
                        }
                    </section>
                </Container>
            </div>
        )
    }

    return null
}