import React from 'react';
import { Container } from 'reactstrap';
import { BrowserRouter as Router } from "react-router-dom"

import loader from './components/common/loader';
import Drawer from './Drawer';

import AppRoutes from './AppRoutes'

import { FixedContent } from './components/layout/GuidesNav'
import { initializeTracking } from './helpers/tracking/tracking'

import { BannerContext } from './contexts/BannerContext'

import './components/Guide/_drawer.scss'
import './components/Guide/_custom.scss'
import './components/Guide/_listing_detail.scss'
import './components/Guide/_rotating_phone.scss'
import RotatingPhoneView from './components/layout/RotatingPhoneView';

const SingleProduct = React.lazy(() => import('./components/layout/SingleProduct'))
const MultipleProduct = React.lazy(() => import('./components/layout/MultipleProduct'))
const Footer = React.lazy(() => import('./components/layout/Footer'))

function App() {


    React.useEffect(() => {
        initializeTracking();
    }, [])

    const [visibleBanner, toggleVisibility] = React.useState(true);

    return (
        <>
            <BannerContext.Provider
                value={{
                    show: visibleBanner,
                    toggle: (flag) => {
                        toggleVisibility(flag)
                    }
                }}
            >

                <Drawer />
                <React.Suspense fallback={loader()}>
                    <div className="init" id="main">
                        <section
                            className="main-app"
                        >
                            <FixedContent />
                            <RotatingPhoneView />
                            <Router basename="rehabs" >

                                <AppRoutes />

                                <SingleProduct />
                                <MultipleProduct />

                                <Footer />
                            </Router>

                        </section>
                    </div>
                </React.Suspense>

            </BannerContext.Provider>
        </>
    );
}

export default App;
