import ReactGA from 'react-ga';


const trackingId = "UA-165896037-1"; // Replace with your Google Analytics tracking ID


export const initializeGoogleAnalytics = () => {

    ReactGA.initialize(trackingId);

}